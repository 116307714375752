export default {
  lang: 'English',
  test: 'test',
  AddToCart: 'Add To Cart',
  changeLangSuccess: 'Language switch successful !',
  changeCurSuccess: 'Currency switch successful !',
  Input: {
    email: 'Please enter the correct email',
    number: 'Please enter a number',
    input: 'Please input ',
    text: '',
    date: 'Please enter a date',
    password: 'Two inconsistent input passwords',
    textarea: '',
    emailNotFound: 'Email Not Found',
    logopassword: 'Please input passwords',
    confirmpassword: 'Two inconsistent input passwords',
    nomessage: 'No Message',
    phoneincorrect: 'The phone number format is incorrect',
    pleaseenterphone: 'Please input phone',
    phone: 'Please input phone'
  },
  Message: {
    Logout: 'Timeout ! login now ?',
    Message: 'Message',
    Confirm: 'Ok',
    Cancel: 'Close',
    msg401: 'Insufficient authority!',
    msg400: 'System abnormal!',
    msg404: 'Request interface does not exist!',
    msg500: 'Server error!',
    msg502: 'Network error!',
    msg503: 'The service is not available, the server is temporarily overloaded or maintained.',
    SucceedInOperating: 'Succeed in Operating',
    SavedSuccessfully: 'Saved successfully',
    Loading: 'Page Loading, Please Wait',
    ViewDetail: 'VIEW DETAILS',
    FollowUs: 'Follow Us',
    Evidence: 'Evidence',
    UploadEvidence: 'Upload Evidence',
    OrderNumber: 'Order Number',
    Remark: 'Remark',
    Image: 'Image',
    SubmitSuccess: 'Submitted Successfully',
    SubmitFailed: 'Submit Failed',
    DeliveryDate: 'Delivery Date',
    DeliveryTime: 'Delivery Time',
    DeliveryInstructions: 'Delivery Instructions',
    DeliveryMark: 'Please make a reservation one day in advance. The order is cut off at 10 PM every day. The Package over 800 packages, urban delivery.Only Payment complete before 10PM can be delivered the next day',
    All: 'All',
    PlsAddPiUpAddr: 'Please add relevant self-referral address information ! ',
    NoneAddPiUpAddr: 'Unable to query the relevant address information!',
    AdditionalDiscount: 'The Additional Discount is In Effect',
    Delete: 'Delete',
    SuccessfullyDeleted: 'Successfully Deleted',
    Save: 'Save',
    SaveAll: 'SaveAll',
    noRMBStripe: 'Currency RMB does not support Stripe payment, please choose another payment method or switch currency mode',
    textareaError: 'Please enter more than 5 characters',
    FindNoProduct: 'The system is busy, please refresh the page again'
  },
  RegNPay: {
    BackText: 'Back',
    UploadButtonText: 'Upload files(jpg, png, pdf)',
    UploadingText: 'Uploading...',
    ConfirmText: 'Confirm and Send',
    UploadSuccessfulText: 'Upload Successful',
    UploadFailText: 'Upload failed',
    NoFileText: 'No file selected',
    UploadLengthText: 'You can only upload {0} files at most',
    UploadSizeText: 'You can only upload {0} M at most',
    PleaseSelect: 'Please Select',
    SubRecord: 'Submission Record',
    ReferenceNum: 'Reference Number',
    SubmissionDate: 'Submission Date',
    Print: 'Print',
    PlsPayWay: 'Please select the mode of payment',
    PreviewTitleText: 'Please confirm your information again.',
    FormatErrorText: 'Please input correct email address.',
    RequiredText: 'Required'
  },
  Account: {
    MemberCenter: 'Member Center',
    MemberInformation: 'User Information',
    MyMessages: 'My Mailbox',
    MyFavorite: 'My Favorite',
    MyOrder: 'My Order',
    DeliveryAddress: 'Delivery Address',
    Logout: 'Logout',
    Welcome: 'WELCOME!',
    SISA: 'Succeed in Choosing'
  },
  product: {
    ProductCode: 'Product Code',
    buy: 'Buy Now',
    addToCart: 'Add To Cart',
    buyNow: 'Buy now',
    favorite: 'Favorite',
    priceTitle: 'Price',
    countTitle: 'Quantity',
    youWouldLike: 'You May Also Like',
    moreTips: 'Click to load more',
    none: 'None',
    noneInCat: 'no prodcut belong to this catalog',
    notifyMe: 'Notify Me',
    purchasing: 'purchasing',
    comments: { title: 'Product Reviews' },
    detail: 'Detail',
    overView: 'OverView',
    specification: 'Specification',
    attr: { 0: 'Size', 1: 'Color' },
    SaleOut: 'Sold Out',
    offSale: 'off sale',
    Recommend: 'Recommend',
    Remarks: 'Remarks',
    EnterRemaks: 'please enter remarks',
    up: 'Last',
    down: 'Next',
    logouted: 'you are logouted!',
    loginow: 'login now',
    successInAdding: 'succeed in adding',
    successInRemoving: 'succeed in removing',
    ProductIntro: 'Product introduction',
    ProductIntroduction: 'Product introduction',
    UserReviews: 'User reviews',
    OwnerReply: 'Owner reply',
    per: 'Page',
    page: '',
    Screening: 'Screening',
    Resetall: 'Reset all filters',
    PriceHL: 'Price from high to low',
    PriceLH: 'Price from low  to high',
    Producttitle: 'Product List',
    Paixu: 'Sort',
    Total: 'Total',
    Product: 'Products',
    ProductDetail: 'Product Detail',
    Unit: 'Unit',
    ProductNumber: 'Product Number',
    productsintotal: 'products in total',
    nocontentyet: 'no content yet'
  },
  Shoppingcart: {
    ShoppingcartTitle: 'Shopping Cart ',
    Merchant: 'Merchant',
    Product: 'Product',
    Quantity: 'Quantity',
    Price: 'Price',
    Total: 'Sub total',
    CheckoutList: 'Check Out',
    Checkout: 'Check Out',
    goTOCart: 'To Shopping Cart',
    vat: 'Include VAT',
    None: 'No article in your Shopping Cart!Shopping now.',
    more: 'More',
    hide: 'Hide',
    Subtotal: 'Sub total：',
    Goshoppingnow: 'Shop Now',
    ViewShoppingCart: 'View Shopping Cart'
  },
  Login: {
    doLogin: 'Login',
    LoginTitle: 'Login',
    NewUser: 'New buyer',
    RegNow: 'Register now',
    ForgetPwd: 'Forget Password',
    RememberMe: 'Remember Me',
    FaceBookUse: 'Login with FaceBook',
    UserName: 'Login Name/Email',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    LoginPwd: 'Please enter your password',
    LoginoldPwd: 'Incorrect password',
    Loginerror: 'Incorrect account or password',
    LoginSucceeded: 'Login Succeeded',
    Account: 'Account'
  },
  Register: {
    RegisterTitle: 'Registration',
    UserEmail: 'Email',
    or: 'or',
    UserRegPassword: 'Password',
    UserNewPassword: 'New Password',
    UserOldPassword: 'Old Password',
    UserRegTips: 'Min.6 characters with letters and numbers',
    UserConfirmPassword: 'Confirm',
    UserFirstName: 'Name',
    UserLastName: 'Surname',
    UserContactNumber: 'Phone',
    UserBirthDate: 'Date of Birth',
    UserGender: 'Gender',
    UserMale: 'Male',
    UserFemale: 'Female',
    UserLanguage: 'Language',
    UserLanguageE: 'English',
    UserLanguageT: 'Traditional Chinese',
    UserLanguageS: 'Simplified Chinese',
    RegisterBtn: 'Register',
    RegisterAgree: 'I agree to the terms of purchase and terms of use',
    RegisterEmail: 'Email cannot be empty',
    RegisterEmail01: 'Please enter a formal mailbox format',
    RegisterPassword: 'Please enter your password',
    RegisterNewPassword: 'Please enter your password again',
    RegisterNewPassword01: 'Two inconsistent input passwords',
    RegisterFirstName: 'Please enter your name',
    RegisterLastName: 'Name please enter your name',
    RegisterAgree_check: 'Please check',
    RegisterMobile: 'Please enter your phone',
    RegisterSuccess: 'Register Succeeded',
    RegisterFail: 'Register Failed',
    RegisterWelcome: 'Congratulations on your successful registration',
    RegisterTips: 'This email address has not been registered, register to become a member immediately!',
    Login: 'Login now!',
    Registerdigital: 'Length from 8 to 20 must contain uppercase letters, lowercase letters and numbers',
    promotionalinformation: 'I agree to receive promotional information'
  },
  DeliveryAddress: {
    AddAddress: 'Add address',
    ChooseDeliveryAddress: 'Select delivery address',
    EditBtn: 'Edit',
    DeleteBtn: 'Delete',
    AddDeliveryAddress: 'New Address',
    PostalCode: 'PostalCode',
    Area: 'Area',
    Province: 'Province',
    District: 'District',
    Address: 'Address',
    DefaultAddress: 'Set as default delivery address?',
    SaveBtn: 'Save',
    ResetBtn: 'Reset',
    CloseBtn: 'Close',
    YesBtn: 'Yes',
    NoBtn: 'No',
    AddFirstName: 'First Name',
    AddLastName: 'Last Name',
    UserContactNumber: 'Phone',
    Mobile: 'Mobile Phone',
    Addressprovince: 'Please choose the province',
    AddressArea: 'Please choose the area',
    Detail: 'detail'
  },
  MyCoupon: {
    MyCoupon: 'My Coupon',
    Coupon: 'Coupon',
    PeriodOfValidity: 'Time',
    NoCoupon: 'No Coupon',
    CouponStatus: 'Coupon Status',
    SelectCouponStatus: 'Please Slect Coupon Status',
    TotalNum: 'Total',
    Piece: 'Piece',
    NotUse: 'Valid',
    DoNotUse: 'Invalid',
    Used: 'Invalid',
    Among: 'Among',
    All: 'All'
  },
  MyFavorite: {
    MyFavorite: 'My Favorite',
    LearnMore: 'Learn More',
    RemoveProduct: 'Remove Product',
    RemoveSuccess: 'Remove Successfully',
    AddSuccess: 'Add Successfully',
    noFavorite: 'No Favorites Favorites'
  },
  Notification: {
    MyMessages: 'My Messages',
    MsgList: 'Message List',
    TotalRecQtyWithSymbol: 'Total Qty. ：',
    UnreadWithSymbol: 'Unread Qty：',
    MsgUnreadOnly: 'Unread Message Only',
    MsgMarkAsRead: 'Mark as read',
    CheckAllOrNot: 'Check All/Not',
    MsgSender: 'Sender',
    MsgSubject: 'Subject',
    MsgSenderDate: 'Send Date',
    MsgReadStatus: 'Status',
    Details: 'Details',
    More: 'More',
    Back: 'Back',
    MsgContent: 'Content',
    Send: 'Send',
    Unread: 'Unread',
    Read: 'Read',
    UpdateSucceeded: 'Update Succeeded!',
    Check: 'Check',
    Id: 'Id'
  },
  Forgetpassword: {
    ForgetPassword: 'Forget Password',
    ResetPwdTips:
      'Please enter the registered email you used for registration, then click Next to continue',
    LoginNameEmail: 'Email',
    NextStep: 'Next',
    ResetPwdSuccess: 'Temp Password is sended to your email,please check.',
    NotEmail: 'Cannot receive the Activate Email',
    ToReEnter: 'Click here to re-enter',
    EnterEmail: 'Please Enter The Email',
    CorrectEmail: 'Please Enter The Correct Email',
    ResetPwd: 'Reset Password'
  },
  MemberInfo: {
    MemberInfoTitle: 'User Information',
    UserAccount: 'User Account',
    ModifyPassword: 'Change Password',
    EnterUserName: 'Please Enter Fristname',
    EnterUserLastName: 'Please Enter Lastname',
    EnterUserPhone: 'Please Enter Phone',
    EnterUserBrithDate: 'Please Select BrithDate',
    EnterOldPwd: 'Please Enter Old Password',
    EnterNewPwd: 'Please Enter New Password',
    EnterNumber: 'required number',
    EnterComfirmPwd: 'Please Enter Comfirm Password',
    PwdNotCorrect: 'The passwords not match twice',
    AgreeEmail: 'I agree that edtoy will send me promotional information through e-mail address',
    DDMM: 'DD/MM'
  },
  Order: {
    OrderTitle: 'My Order',
    OrderDetail: 'Order Detail',
    OrderPay: 'Pay Now',
    OrderCancel: 'Order Cancel',
    OrderComplete: 'Order Complete',
    OrderClose: 'Order Close',
    CartSubtotal: 'Cart Subtotal',
    DeliveryCharge: 'Delivery Charge',
    TotalPrice: 'Total Price',
    OrderStatus: 'Order Status',
    OrderDateTime: 'Order Date & Time',
    OrderNumber: 'Order Number',
    OrderSearch: 'Order Search',
    EnterProductName: 'Please Enter Product Name',
    Items: 'ITEMS',
    OrderComment: 'Comment',
    PromotionCode: 'Promotion Code',
    RecommendationCode: 'Recommendation Code',
    CommodityPrice: 'Commodity Price',
    Full: 'Buy',
    Minus: 'Free',
    Hit: 'Free',
    Precent: 'Precent off',
    Discount: 'Discount Price',
    Cancel: 'Cancel',
    Paid: 'Paid',
    Unpaid: 'Unpaid'

  },
  Payment: {
    ReturnHomePage: 'Return HomePage',
    PayStatus: 'Pay Status',
    PayFailTips:
      'The payment was fail, pleasse try again after checking of your account.',
    PaysuccessTips: 'The payment was successful.'
  },
  CheckOut: {
    Ordersummary: 'Order Summary',
    Input: 'Input',
    expiryDate: 'Expiry Date',
    awsl: 'awsl',
    expressError: 'Express outage!Please choose others.',
    confirmAddress: 'Please confirm the delivery address first',
    pay: 'Pay',
    CheckOutTitle: 'Check Out',
    Merchant: 'Merchant',
    Product: 'Product',
    Qty: 'Qty',
    Price: 'Price',
    SplitDelivery: 'Split Delivery',
    ShippingMethod: 'Shipping Method',
    PleaseSelect: 'Please select',
    Selected: 'Selected',
    Name: 'Name',
    Phone: 'Phone',
    Address: 'Address',
    Pickup: 'Pick up',
    Change: 'Change',
    DeliveryCharge: 'Delivery Charge',
    SubTotal: 'Sub-Total',
    PayBy: 'Pay By',
    Couponcode: 'Promotion Code',
    confirm: 'Confirm',
    Total: 'Total',
    BacktoShoppingCart: 'Back to Shopping Cart',
    Last: 'Previous',
    Checkout: 'Check Out',
    Next: 'Next',
    Delivery: 'Delivery',
    SelectDeliveryAddress: 'Select delivery address',
    LastName: 'Last Name',
    Edit: 'Edit',
    Delete: 'Delete',
    Select: 'Select',
    NewAddress: 'New Address',
    PickUp: 'Pick Up',
    FirstName: 'First Name',
    ZipCode: 'Zip Code',
    Mobile: 'Mobile',
    Country: 'Area',
    Province: 'Region / Province',
    City: 'District',
    Close: 'Close',
    PickupDate: 'Pick up Date',
    PickupTime: 'Pick up Time',
    Morning: 'Morning',
    Afternoon: 'Afternoon',
    PickAddressError: 'Incomplete PickAddress information',
    AddressLock: 'This Address has been Selected',
    CompanyName: 'Company Name',
    CompanyPhone: 'Company Phone',
    CompanyAddress: 'Company Address',
    PickupAddress: 'Pick up Address',
    Save: 'Save',
    PleaseChoose: 'Please Choose',
    PleaseSelectdate: 'Please Select date',
    PleaseSelecttime: 'Please select time',
    ConfirmationOfOrder: 'Confirmation Of Order',
    Concessions: 'Concessions',
    PaymentMethod: 'Payment Method',
    Complete: 'Complete',
    OrderPreview: 'Order Preview',
    CandP: 'Cant use coupon and promotionCode at the same time.',
    promotionCodeError: 'Please enter the correct promotion code',
    Use: 'Use',
    As: 'As',
    PaymentMethodError: 'The payment method has not been selected yet!',
    OrderSent: 'Order has been sent',
    PaymentProcess: 'Please complete the payment process',

    CountryName: 'Country Name',
    ProvinceName: 'Province Name',
    CityName: 'City Name',
    SFPointType: 'Self Point Type'
  },
  Concessions: {
    Cannot: 'Offer cannot be used at the same time'
  },
  Address: {
    Country: 'Please choose country',
    Province: 'Please choose province',
    Address: 'Please enter detail address'
  },
  MemberPoints: {
    MemberPoints: 'Member Points',
    PointsDetail: 'Points Detail',
    PointsIncome: 'Points Income',
    PointsSpending: 'Points Spending',
    OrderTime: 'Order Time',
    OrderNo: 'Order Number',
    Income: 'Income',
    Spending: 'Expenditure',
    IncomeSpending: 'Income/Spending',
    DetailedDescription: 'Detailed Description',
    TotalPoints: 'Total Integral',
    Hello: 'Hello',
    Equals: 'Equals',
    Integral: 'Integral',
    PointsTips: 'Frequently asked questions about integral use',
    PointsType: 'Points Type',
    Points: 'Points',
    PointsMode: 'Points Mode'
  },
  Cms: {
    AboutUS: 'About US',
    OurStores: 'OUR STORES',
    BusinessHours: 'Business Hours',
    BigSales: 'BIG SALES',
    WhatNews: 'WHAT’S NEWS',
    PlsChoose: 'Please click to select the content you want to view :',
    LastrasCollections: 'Lastra’s Collections',
    Exploremore: 'Explore More'
  },
  promotion: {
    enter: 'enter',
    main: 'The best choice for gifted children',
    monterssori: {
      title1: 'Montessori teaching method',
      title2: 'Develop the habit of independent learning and exploration',
      banner1: 'Be your own teacher',
      banner2: 'Initiative learning',
      banner3: 'build personality'
    }
  },
  home: {
    News: 'News',
    More: 'More',
    Category: 'Category',
    Weaccept: 'We Accept',
    Order: 'Order',
    TelSearch: 'Tel',
    AllProduct: 'All Product',
    ResetProduct: 'Reset',
    Address: 'Address',
    ViewDetail: 'Detail',
    Screening: 'Screening',
    Resetall: 'Reset all filters',
    PriceHL: 'Price from high to low',
    PriceLH: 'Price from low  to high',
    Thatsall: 'That is all',
    textmessage: 'This is a test text message, you can put text messages.',
    LatestFromLastras: 'Latest From Lastras',
    PaymentMethod: 'Payment Method',
    Discover: 'Discover Collection',
    YouDeserveIt: 'You Deserve It',
    ShopNow: 'Shop Now',
    Pleaseinputkeywords: 'Please input keywords'
  },
  Action: {
    ReturnHomePage: 'Return Homepage',
    OrderDetails: 'Order Details',
    PaymentSuccessTips: 'Payment Successful',
    PaymentFailTips: 'Payment Fail',
    Checkout: 'Check Out',
    Save: 'Save',
    Confirm: 'Confirm',
    PromotionCodeTips: 'Please enter the PromotionCode',
    Share: 'Share',
    LoadMore: 'Load More',
    FacebookLogin: 'Facebook Login',
    HotPot: 'Hot Pot',
    BBQ: 'BBQ',
    Dishes: 'Dishes',
    Package: 'Package',
    ProductSearch: 'Product Search',
    Total: 'Total',
    Products: 'Products',
    SearchPiUpAddr: 'Search for pickup point address'
  },
  accountHeader: {
    MemberFile: 'Membership File',
    Favorite: 'Favorite',
    GrantsCredits: 'Grants Credits',
    MyOrder: 'My Order',
    ECoupon: 'ECoupon',
    MembershipDiscount: 'Membership Discount'
  },
  regnpay: {
    successful: 'Thank you for your submit',
    reply: 'We will reply you as soon as possible',
    ContactUs: 'Contact Us'
  },
  messageTips: {
    NoContent: 'No Content',
    NoComments: 'No Comments',
    NoProduct: 'Sorry, there are no products you searched for'
  }
};
